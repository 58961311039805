import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        "Remarkable attention to detail and unwavering commitment to excellence. Consistently impressed with the quality of work. A true professional who consistently exceeds expectations. Exceptional service every time!",
    },
    {
      img: profilePic2,
      review:
        "Exemplary work ethic and attention to detail set this professional apart. Always exceeds expectations, delivering outstanding results. A pleasure to work with - highly recommended for exceptional quality and dedication.",
    },
    {
      img: profilePic3,
      review:
        "Consistently impressed with the outstanding work provided. Exceptional attention to detail and a commitment to excellence shine through. A reliable and skilled professional who goes above and beyond. Exceptionally pleased with the results.",
    },
    {
      img: profilePic4,
      review:
        "Exceptional work, consistently surpassing expectations. Demonstrates a rare combination of creativity, precision, and reliability. A true asset to any project, delivering outstanding results with a commendable work ethic.",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
